@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "DB Helvethaica X";
    src: local("DB Helvethaica X"),
        url("assets/fonts/DB-Helvethaica-X-Bd-v3.2.ttf") format("truetype");
    font-weight: 700;
}

@font-face {
    font-family: "DB Helvethaica X";
    src: local("DB Helvethaica X"),
        url("assets/fonts/DB-Helvethaica-X-Blk-v3.2.ttf") format("truetype");
    font-weight: 800;
}

@font-face {
    font-family: "DB Helvethaica X";
    src: local("DB Helvethaica X"),
        url("assets/fonts/DB-Helvethaica-X-It-v3.2.ttf") format("truetype");
    font-style: italic;
}

@font-face {
    font-family: "DB Helvethaica X";
    src: local("DB Helvethaica X"),
        url("assets/fonts/DB-Helvethaica-X-Li-v3.2.ttf") format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: "DB Helvethaica X";
    src: local("DB Helvethaica X"),
        url("assets/fonts/DB-Helvethaica-X-Med-v3.2.ttf") format("truetype");
    font-weight: 600;
}

@font-face {
    font-family: "DB Helvethaica X";
    src: local("DB Helvethaica X"),
        url("assets/fonts/DB-Helvethaica-X-Thin-v3.2.ttf") format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: "DB Helvethaica X";
    src: local("DB Helvethaica X"),
        url("assets/fonts/DB-Helvethaica-X-UlLi-v3.2.ttf") format("truetype");
    font-weight: 200;
}

@font-face {
    font-family: "DB Helvethaica X";
    src: local("DB Helvethaica X"),
        url("assets/fonts/DB-Helvethaica-X-v3.2.ttf") format("truetype");
}


@font-face {
    font-family: "Kanit";
    src: local("Kanit"),
        url("assets/fonts/Kanit-BoldItalic.ttf") format("truetype");
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: "Kanit";
    src: local("Kanit"),
        url("assets/fonts/Kanit-Medium.ttf") format("truetype");
    font-weight: 500;
}

body {
    height: 100%;
    margin: 0;
    font-family: 'DB Helvethaica X', 'Kanit', serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f2f2f2;
}

.MuiInput-input {
    font-family: 'DB Helvethaica X', 'Kanit', serif !important;
}

.MuiInput-input:disabled {
    background: rgba(242, 242, 242, 0.5);
}

.wrapper {
    min-height: calc(100vh - 150px);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.text-ellipsis {
    display: inline-block;
    width: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.footer {
    width: 100%;
    background-color: #ffffff;
    font-size: 16px;
    margin-top: 40px;
}

.top-logo {
    background-color: #ffffff;
    width: 100%;
    background-repeat: no-repeat;
    height: 60px;
    /** disable repeating */
}

.shadow {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16), 0 7px 16px rgba(0, 0, 0, 0.04);
}


#register-main {
    margin-top: -20px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #ffffff;
}

#register-main #registerStep1,
#registerStep2 {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    display: unset;
    width: 100%;
}

/* dogbook page  */
.gradientBg {
    background: linear-gradient(152.04deg, #0A4197 -0.97%, #209FED 81.57%);
}

.action-button-panel button {
    margin-top: 8px;
}

.primary-color {
    color: #209FED !important;
}

.title-size {
    font-size: 28px !important;
}

.space-li li {
    padding-bottom: 5px;
}

.MuiSelect-iconStandard {
    fill: #209FED !important;
}

.MuiFormLabel-root {
    color: #8F8F8F !important;
}

.MuiInput-underline:after {
    border-bottom: 2px solid #209FED !important;
}

.MuiInput-root::before {
    border-bottom: 1px solid #E5E5E5 !important;
}

.MuiFormLabel-asterisk {
    color: #FF2B1E !important;
}

.MuiSelect-select {
    padding-left: 15px !important;
}

.MuiInputLabel-root span {
    display: none !important;
}

.MuiInputLabel-shrink span {
    display: unset !important;
}

.MuiInputBase-root input,
.MuiInputLabel-root {
    padding-left: 15px !important;
}

.MuiInputLabel-root.MuiInputLabel-shrink {
    padding-left: 0 !important;
}

.MuiInputBase-root input {
    height: 1.1em !important;
    caret-color: #209fed;
}

.MuiInput-root.Mui-error:before {
    border-bottom-color: #d32f2f !important;
}

.MuiFormHelperText-root {
    display: none;
}

.MuiFormHelperText-root.Mui-error {
    display: block;
}

.MuiRadio-root.Mui-checked {
    color: #0A4197 !important;
}

#cbxDeliveryAddress .MuiFormControlLabel-label {
    font-weight: bold !important;
}

.border-address-list:not(:last-child) {
    border-bottom: 1px rgb(209 213 219) solid;
}

.h-input {
    height: 71px;
}

.slide {
    transition: height 0.5s ease-in-out;
    overflow: hidden;
}

.slide.up {
    height: 0;
}

.slide.down {
    height: auto;
}


.MuiFormControl-root.MuiTextField-root.text-area {
    border: 1px solid #E5E5E5;
    border-radius: 6px;
    /* border-bottom: 0px; */
}

.MuiFormControl-root.MuiTextField-root.text-area .css-bw5hs9-MuiInputBase-root-MuiInput-root:before {
    border: 0;
    width: 0;
}

.text-area textarea {
    padding-left: 8px;
    padding-right: 8px;
}

.text-area div::before {
    content: none;
}

.text-area:after {
    bottom: -1px;
    border-bottom: 2px solid #209FED !important;
    width: 99.50%;
    left: 1px;
}

/* Swiper */


.swiper-pagination-bullet.swiper-pagination-bullet-active {
    /* width: 74px !important; */
    background-color: #0A4197 !important;
    color: #0A4197 !important;
    border: none !important;
}

.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    width: 16px;
    height: 16px;
}


.swiper-pagination-bullet {
    border-radius: 16px !important;
    transition: .3s opacity;
    border: 1px solid #0A4197 !important;
    color: #E9E9E9;
    background-color: #E9E9E9 !important;
}

.swiper {
    padding-bottom: 40px !important;
}

.swiper-container {
    overflow: visible !important;
}

.swiper-pagination {
    position: absolute;
    bottom: 0 !important;
}

#qr-shaded-region {
    border-color: rgba(0, 0, 0, 0) !important;
}

#qr-shaded-region div {
    background-color: #c4c4c4 !important;
}


.MuiDialogContent-root .MuiPickersToolbar-root.MuiDatePickerToolbar-root {
    display: none;
}

video {
    object-fit: cover
}

.gradientBlue {
    position: relative;
    background: linear-gradient(152.04deg, #0A4197 -0.97%, #209FED 81.57%);
    z-index: 2;
}
.gradientBlue::before {
    border-radius: inherit;
    z-index: -1;
    top: 0;
    position: absolute;
    content: "";
    background: rgba(23, 65, 151, 0.5);
    width: 100%;
    height: 100%;
}

.page-f-screen {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.bg-img-blue {
    background-image: url('assets/images/bg-blue.svg');
    background-repeat: no-repeat;
    background-size: cover;
}

.h-screen-logo {
    min-height: calc(100vh - 60px);
}
.body-container {
    height: calc(100vh - 150px);
}

.quiz-no {
    background: linear-gradient(0deg, #209FED, #209FED), rgba(32, 159, 237, 0.12);
    border-radius: 12px;
    padding-top: 6px;
    padding-bottom: 4px;
    padding-left: 23px;
    padding-right: 23px;
}
.quiz-no > p{
    color: #FFFFFF;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    font-family: 'Venera';
    font-weight: 900;
    font-size: 12px;
    line-height: 14px;
}

.qr-read-tag > div > svg {
    border: 0 !important;
}

.tag-status-Pending {
    border-color: #968D0B !important;
}
.tag-status-Pending > p {
    color: #968D0B !important;
}
.tag-status-Approve {
    border-color: #0A4197 !important;
}
.tag-status-Approve > p {
    color: #0A4197 !important;
}
.tag-status-Reject {
    border-color: #971F0A !important;
}
.tag-status-Reject > p {
    color: #971F0A !important;
}

.text2-line {
    display: -webkit-box;
    /* max-width: 400px; */
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

div.historyList {
    background-color: #F2F2F2;
    height: 10px;
}

div.historyList.true {
    background-color: #F2F2F2;
    height: 0px;
}

.pet-range-picker {
}

.pet-range-picker > .input{
    position: relative;
    /* background-color: pink; */
}
.pet-range-picker > .input > .picker {
    position: absolute;
}

.box-1234 > div > div {
    /* background-color: #209FED; */
    margin: 0 !important;
    width: 320px;
    height: 380px;
}

.rmdp-wrapper {
    box-shadow: none !important;
    position: relative;
}
.rmdp-top-class  {
    position: absolute;
    top: 30px !important;
    left: 32px !important;
    transform: scale(1.2) !important;
}

.rmdp-calendar   {

}

.rmdp-range {
    background-color: #0A4197 !important;
    box-shadow: none !important;
    /* outline: 1px solid #0A4197; */
}
.rmdp-day.rmdp-today span {
    background-color:  #1976D2 !important;
}
.rmdp-left i {
    margin-right: 2px;
    margin-top: 6px;
}
.rmdp-right i {
    margin-right: 2px;
    margin-top: 6px;
}

.sh-card-member {
    background: linear-gradient(180deg, #185ECC 0%, #0A4197 100%), linear-gradient(180deg, #FFFFFF 0%, #F5F5F5 100%);
    box-shadow: 0px 4px 8px rgb(0 0 0 / 16%), 0px 7px 16px rgb(0 0 0 / 4%);
    border-radius: 12px
}

.tier-progress {
    position: relative;
    /* height: 30px; */
    /* width: 100%; */
    aspect-ratio: 100/8.57;
}
.tier-progress > .tier-box{
    position: absolute;
    display: flex;
    align-content: center;
    justify-content: center;
    height: 100%;
    width: 36%;
    background: linear-gradient(270deg, #4886E7 5.82%, #185ECC 16.51%, #0A4197 49.86%);
    border-radius: 40px;
    z-index: 3;
}

.tier-progress > .tier-box-silver {
    position: absolute;
    display: flex;
    align-content: center;
    justify-content: center;
    height: 100%;
    width: 48%;
    background: linear-gradient(270deg, #D1D1D1 6.73%, #7F7F7F 39.37%);
    border-radius: 40px;
    z-index: 3;
}

.tier-progress > .tier-box-gold{
    position: absolute;
    display: flex;
    align-content: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background: linear-gradient(270deg, #FCE28C 9.8%, #FCC201 24.38%, #EEB609 49.86%);
    border-radius: 40px;
    z-index: 3;
}

.tier-progress > .tier-box-gold{
    position: absolute;
    display: flex;
    align-content: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background: linear-gradient(270deg, #FCE28C 9.8%, #FCC201 24.38%, #EEB609 49.86%);
    border-radius: 40px;
    z-index: 3;
}
.tier-progress::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background: #F4D282;
    opacity: 0.6;
    border-radius: 40px;
    z-index: 1;
}
.tier-progress::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 48%;
    background: #C1C1C1;
    opacity: 0.6;
    border-radius: 40px;
    z-index: 2;
}
.tier-filtter {
    background: linear-gradient(133.15deg, #DEF2FF 0.21%, rgba(222, 242, 255, 0) 110.71%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}
/* .tier-progress > div{
    position: absolute;
}
.tier-progress > div.a {
    width: 350px;
    height: 30px;
    background: #F4D282;
    opacity: 0.6;
    border-radius: 40px;
} */

#quiz-page {
    max-width: 500px;
    margin: auto;
    
    /* aspect-ratio: 16/9; */
}
#quiz-page p.title-quiz {
    font-size: clamp(36px, 4vw, 48px);
}
/* #quiz-page p.title-quiz {
    font-size: clamp(36px, 4vw, 48px);
} */

.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (min-width: 500px) {
    #quiz-page p.title-quiz {
        /* background: palegreen; */
        font-size: 48px !important;
    }
}
@media (max-height: 450px), (max-width: 450px) {
    .rmdp-day, .rmdp-week-day {
        height: 34px !important;
        width: 34px !important;
    }
}



/* NEW CSSS */
#bTextEditor * {
    font-size: 18px;
    line-height: 140%;
    margin-bottom: 16px;
    color: #8f8f8f
  }
  
  #bTextEditor *:last-child,
  #bTextEditor * *>* {
    margin-bottom: 0
  }
  
  #bTextEditor h1,
  #bTextEditor h2,
  #bTextEditor h3,
  #bTextEditor h4,
  #bTextEditor h5,
  #bTextEditor h6 {
    margin-bottom: 16px;
    color: #000
  }
  
  #bTextEditor h1 {
    font-size: 36px
  }
  
  #bTextEditor h2 {
    font-size: 32.4px
  }
  
  #bTextEditor h3 {
    font-size: 28.8px
  }
  
  #bTextEditor h4 {
    font-size: 25.2px
  }
  
  #bTextEditor h5 {
    font-size: 21.6px
  }
  
  #bTextEditor h6 {
    font-size: 18px
  }
  
  #bTextEditor img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto
  }
  
  #bTextEditor span {
    /* color: #209FED */
  }
  
  #bTextEditor a {
    word-break: break-all;
    color: #209FED !important;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    -ms-transition: all .3s ease
  }
  
  #bTextEditor a:hover {
    text-decoration: underline;
    color: #0A4197
  }
  
  #bTextEditor blockquote {
    color: #8f8f8f;
    background: #f9f9f9;
    border-left: 10px solid #0A4197;
    margin: 1.5em 10px;
    padding: .5em 10px;
    quotes: "“" "”" "‘" "’"
  }
  
  #bTextEditor blockquote:before {
    color: #209FED;
    content: open-quote;
    font-size: 4em;
    line-height: .1em;
    margin-right: .25em;
    vertical-align: -0.4em
  }
  
  #bTextEditor blockquote p {
    display: inline
  }
  
  #bTextEditor hr {
    width: 100%;
    height: 1px;
    background-color: #7F8589;
  }
  
  #bTextEditor table {
    width: 100%
  }
  
  #bTextEditor table td {
    padding: 6px 6px
  }
  
  #bTextEditor dl {
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0
  }
  
  #bTextEditor dl dt,
  #bTextEditor dl dd,
  #bTextEditor dl dt>*,
  #bTextEditor dl dd>* {
    margin-bottom: 18px
  }
  
  #bTextEditor dl dt:last-child,
  #bTextEditor dl dd:last-child,
  #bTextEditor dl dt>*:last-child,
  #bTextEditor dl dd>*:last-child {
    margin-bottom: 0
  }
  
  #bTextEditor ul li {
    list-style: disc;
    margin-bottom: 0
  }
  
  #bTextEditor b {
    font-weight: bold
  }
  
  #bTextEditor strong {
    font-weight: bolder
  }